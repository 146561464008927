import { PipelinePlugin } from '@ads/plugin-pipeline';
import { AppCrashService } from '@/features/app-crash';
import { LoggerService } from '@/features/core/logger';
import { RestageOrderPluginDto } from '../types';
import { EmitHandoverAbortedBusEventPlugin } from './emit-handover-aborted-bus-event';
import { TrackEventRestagingCompletedPlugin } from './track-event-restaging-completed';
import { UpdateLocalStatusPlugin } from './update-local-status';

export class AppCrashPlugin implements PipelinePlugin<RestageOrderPluginDto> {
  public readonly requiredPlugins = [
    EmitHandoverAbortedBusEventPlugin,
    TrackEventRestagingCompletedPlugin,
    UpdateLocalStatusPlugin,
  ];

  constructor(
    private appCrashService: AppCrashService,
    private loggerService: LoggerService,
  ) {}

  public async execute(
    dataTransferObject: RestageOrderPluginDto,
  ): Promise<RestageOrderPluginDto> {
    await this.appCrashService.updateProcessedOrderData(
      dataTransferObject.order,
    );

    this.loggerService.debug(
      `Order crash feature unset for order ${dataTransferObject.order.id}`,
      {
        order: dataTransferObject.order,
      },
    );

    return dataTransferObject;
  }
}
